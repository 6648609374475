import React from "react"

import Layout from "../../components/Layout"

const ContactThankYou = () => {
    return (
        <Layout>
            <h1>Thank you</h1>
        </Layout>
    )
}

export default ContactThankYou